import { AdminGuard } from './guard/admin.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

const routes: Routes = [
   { path: '', loadChildren: './employee/employee.module#EmployeeModule'},
   { path: 'manage', loadChildren: './admin/admin.module#AdminModule'},
   { path: 'account', loadChildren: './account/account.module#AccountModule'},
   { path: 'start-assessment', loadChildren: './start-assessment/start-assessment.module#StartAssessmentModule'},
   // { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
